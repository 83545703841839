.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.timestamp {
  width: 100%;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.status {
  padding: 0px 25px;
  display: flex;
  justify-content: center;
  border-top: 2px solid #3e70ff;
  position: relative;
  transition: all 200ms ease-in;
}

.status span {
  font-size: 14px;
  padding-top: 5px;
}

.status span:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #e8eeff;
  border-radius: 25px;
  border: 2px solid #3e70ff;
  position: absolute;
  top: -6px;
  left: calc(50% - 12px);
  transition: all 200ms ease-in;
}

.swiper-control {
  text-align: right;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
}

.swiper-slide:nth-child(2n) {
  width: 40%;
}

.swiper-slide:nth-child(3n) {
  width: 20%;
}
.card {
  border-radius: 15px;
  box-shadow: 0px 1px 13px 0px #0000003b;
}
.searchable-container {
  max-width: 1140px;
  margin: 0 auto;
}

.searchable-items {
  padding: 13px;
  border: 1px solid #e0e6ed;
  border-radius: 10px;
}

.searchable-container .searchable-items {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.searchable-container .items {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 0.625rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  justify-content: space-between;
  background: #fff;
  margin-bottom: 15px;
  border-radius: 14px;
  padding: 13px 18px;
  width: 100%;
  color: #0e1726;
  min-width: 625px;
  transition: all 0.35s ease;
  cursor: pointer;
  -webkit-box-shadow: 0px 2px 9px 2px rgba(31, 45, 61, 0.1);
  box-shadow: 0px 2px 9px 2px rgba(31, 45, 61, 0.1);
}
.searchable-container .items:hover {
  -webkit-transform: translateY(0) scale(1.03);
  transform: translateY(0) scale(1.03);
  transform: translateY(0) scale(1.01);
}
.searchable-container .items .user-profile {
  display: flex;
}
.searchable-container .items .user-profile img {
  width: 43px;
  height: 43px;
  border-radius: 5px;
}
.searchable-container .items .user-name p,
.searchable-container .items .user-work p,
.searchable-container .items .user-email p {
  margin-bottom: 0;
  color: #888ea8;
  font-weight: 600;
}
.searchable-container .items .user-status span.badge {
  background: transparent;
  transform: none;
}
.searchable-container .items .user-status span.badge.badge-warning {
  color: #e2a03f;
  border: 2px dashed #e2a03f;
}
.searchable-container .items .user-status span.badge.badge-danger {
  color: #e7515a;
  border: 2px dashed #e7515a;
}
.searchable-container .items .user-status span.badge.badge-primary {
  color: #4361ee;
  border: 2px dashed #4361ee;
}
.searchable-container .items .action-btn p {
  margin-bottom: 0;
  color: #3b3f5c;
  cursor: pointer;
  font-weight: 600;
}
.searchable-container .items:hover .serial-number p,
.searchable-container .items:hover .user-name p,
.searchable-container .items:hover .user-work p,
.searchable-container .items:hover .user-email p,
.searchable-container .items:hover .action-btn p {
  color: #4361ee;
}
.item-content {
  width: 100%;
  display: flex;
}

li {
  list-style: none;
}

.badge {
  display: inline-block;
  padding: 4px 5px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  width: -webkit-fill-available;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.table-controls {
  margin: 0;
  padding: 0;
}

.page-content {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.search-bar {
  width: 70% !important;
  box-shadow: none;
}
.content-card {
  margin-top: 80px;
}
.nav-item .active {
  color: #4361ee !important;
}
.timeline {
  margin: 0 0 0 180px !important;
  width: 100%;
}
.notification-item {
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 1px 13px 0px #0000003b;
  padding: 10px;
  background: #e8ebf1;
}
.date-input {
  height: calc(1.4em + 1.4rem + 2px);
  border: none;
  padding: 11px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 4px 2px rgba(31, 45, 61, 0.1);
}
.zoomable-image {
  width: auto !important;
  height: auto !important;
  border-radius: 5px !important;
}

.stats-tab thead tr th {
  text-align: center;
}
.stats-tab tbody tr td {
  text-align: center;
}
.stats-tab tfoot tr td {
  text-align: center;
}
.single {
  border-bottom: none !important;
}
.custom-date-picker {
  border: 1px solid #b4b4b4;
  border-radius: 5px !important;
  box-shadow: 0 0 4px 2px rgba(31, 45, 61, 0.1);
  outline: none;
  box-shadow: none;
  padding: 8px; /* Adjust padding if needed */
  font-size: 16px; /* Adjust font size if needed */
}
.pieces {
  flex: auto;
  max-width: unset;
}
.piecess {
  flex: 0 0 4.33333%;
  max-width: 3.33333%;
}



a, a:hover {
  text-decoration: none;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.wrapper {
  width: 100%;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 50px 20px;
  padding-top: 100px;
  display: flex;
  background-image: linear-gradient(-20deg, #727cf5 0%, #031a61 100%);
  display: flex;
  background-image: linear-gradient(-20deg, #727cf5 0%, #031a61 100%);
}
@media screen and (max-width: 768px) {
  .wrapper {
    height: auto;
    min-height: 100vh;
    padding-top: 100px;
  }
}

.profile-card {
  width: 100%;
  min-height: 460px;
  margin: auto;
  box-shadow: 0px 8px 60px -10px rgba(13, 28, 39, 0.6);
  background: #fff;
  border-radius: 12px;
  max-width: 700px;
  position: relative;
}
.profile-card.active .profile-card__cnt {
  filter: blur(6px);
}
.profile-card.active .profile-card-message,
.profile-card.active .profile-card__overlay {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0.1s;
}
.profile-card.active .profile-card-form {
  transform: none;
  transition-delay: 0.1s;
}
.profile-card__img {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 4;
  box-shadow: 0px 5px 50px 0px #6c44fc, 0px 0px 0px 7px rgba(107, 74, 255, 0.5);
}
@media screen and (max-width: 576px) {
  .profile-card__img {
    width: 120px;
    height: 120px;
  }
}
.profile-card__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.profile-card__cnt {
  margin-top: -35px;
  text-align: center;
  padding: 0 20px;
  padding-bottom: 40px;
  transition: all 0.3s;
}
.profile-card__name {
  font-weight: 700;
  font-size: 24px;
  color: #6944ff;
  margin-bottom: 15px;
}
.profile-card__txt {
  font-size: 18px;
  font-weight: 500;
  color: #324e63;
  margin-bottom: 15px;
}
.profile-card__txt strong {
  font-weight: 700;
}
.profile-card-loc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.profile-card-loc__icon {
  display: inline-flex;
  font-size: 27px;
  margin-right: 10px;
}
.profile-card-inf {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 10px;
}
.profile-card-inf__item {
  padding: 10px 35px;
}
@media screen and (max-width: 768px) {
  .profile-card-inf__item {
    padding: 10px 10px;
  }
}
.profile-card-inf__title {
  font-weight: 700;
  color: #324e63;
}
.profile-card-inf__txt {
  font-weight: 500;
  margin-top: 7px;
}
.profile-card-social {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.profile-card-social__item {
  display: inline-flex;
  width: 55px;
  height: 55px;
  margin: 15px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #405de6;
  box-shadow: 0px 7px 30px rgba(43, 98, 169, 0.5);
  position: relative;
  font-size: 21px;
  flex-shrink: 0;
  transition: all 0.3s;
}
@media screen and (max-width: 768px) {
  .profile-card-social__item {
    width: 50px;
    height: 50px;
    margin: 10px;
  }
}
@media screen and (min-width: 768px) {
  .profile-card-social__item:hover {
    transform: scale(1.2);
  }
}
.profile-card-social__item.facebook {
  background: linear-gradient(45deg, #3b5998, #0078d7);
  box-shadow: 0px 4px 30px rgba(43, 98, 169, 0.5);
}
.profile-card-social__item.twitter {
  background: linear-gradient(45deg, #1da1f2, #0e71c8);
  box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.7);
}
.profile-card-social__item.instagram {
  background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  box-shadow: 0px 4px 30px rgba(120, 64, 190, 0.6);
}
.profile-card-social__item.behance {
  background: linear-gradient(45deg, #1769ff, #213fca);
  box-shadow: 0px 4px 30px rgba(27, 86, 231, 0.7);
}
.profile-card-social__item.github {
  background: linear-gradient(45deg, #333333, #626b73);
  box-shadow: 0px 4px 30px rgba(63, 65, 67, 0.6);
}
.profile-card-social__item.codepen {
  background: linear-gradient(45deg, #324e63, #414447);
  box-shadow: 0px 4px 30px rgba(55, 75, 90, 0.6);
}
.profile-card-social__item.link {
  background: linear-gradient(45deg, #d5135a, #f05924);
  box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.6);
}
.profile-card-social .icon-font {
  display: inline-flex;
}
.profile-card-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
@media screen and (max-width: 576px) {
  .profile-card-ctr {
    flex-wrap: wrap;
  }
}
.profile-card__button {
  background: none;
  border: none;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  font-size: 19px;
  margin: 15px 35px;
  padding: 15px 40px;
  min-width: 201px;
  border-radius: 50px;
  min-height: 55px;
  color: #fff;
  cursor: pointer;
  backface-visibility: hidden;
  transition: all 0.3s;
}
@media screen and (max-width: 768px) {
  .profile-card__button {
    min-width: 170px;
    margin: 15px 25px;
  }
}
@media screen and (max-width: 576px) {
  .profile-card__button {
    min-width: inherit;
    margin: 0;
    margin-bottom: 16px;
    width: 100%;
    max-width: 300px;
  }
  .profile-card__button:last-child {
    margin-bottom: 0;
  }
}
.profile-card__button:focus {
  outline: none !important;
}
@media screen and (min-width: 768px) {
  .profile-card__button:hover {
    transform: translateY(-5px);
  }
}
.profile-card__button:first-child {
  margin-left: 0;
}
.profile-card__button:last-child {
  margin-right: 0;
}
.profile-card__button.button--blue {
  background: linear-gradient(45deg, #1da1f2, #0e71c8);
  box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.4);
}
.profile-card__button.button--blue:hover {
  box-shadow: 0px 7px 30px rgba(19, 127, 212, 0.75);
}
.profile-card__button.button--orange {
  background: linear-gradient(45deg, #d5135a, #f05924);
  box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.35);
}
.profile-card__button.button--orange:hover {
  box-shadow: 0px 7px 30px rgba(223, 45, 70, 0.75);
}
.profile-card__button.button--gray {
  box-shadow: none;
  background: #dcdcdc;
  color: #142029;
}
.profile-card-message {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 130px;
  padding-bottom: 100px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.profile-card-form {
  box-shadow: 0 4px 30px rgba(15, 22, 56, 0.35);
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 35px;
  transform: scale(0.8);
  position: relative;
  z-index: 3;
  transition: all 0.3s;
}
@media screen and (max-width: 768px) {
  .profile-card-form {
    max-width: 90%;
    height: auto;
  }
}
@media screen and (max-width: 576px) {
  .profile-card-form {
    padding: 20px;
  }
}
.profile-card-form__bottom {
  justify-content: space-between;
  display: flex;
}
@media screen and (max-width: 576px) {
  .profile-card-form__bottom {
    flex-wrap: wrap;
  }
}
.profile-card textarea {
  width: 100%;
  resize: none;
  height: 210px;
  margin-bottom: 20px;
  border: 2px solid #dcdcdc;
  border-radius: 10px;
  padding: 15px 20px;
  color: #324e63;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  outline: none;
  transition: all 0.3s;
}
.profile-card textarea:focus {
  outline: none;
  border-color: #8a979e;
}
.profile-card__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(22, 33, 72, 0.35);
  border-radius: 12px;
  transition: all 0.3s;
}





.arc {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-top: 2px solid #ffea29;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  animation: rt 2s infinite linear;
}
.arc::before {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-top: 2px solid #8d29ff;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  animation: rt 4s infinite linear reverse;
  content: "";
}
.arc::after {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  border-top: initial;
  border-left: initial;
  border-right: initial;
  animation: cw 1s infinite;
  content: "";
  background: snow;
}

h1 {
  position: absolute;
  height: 40px;
  margin: auto;
  top: 200px;
  left: 0;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  font-size: 14px;
  font-weight: lighter;
  color: white;
}
h1 span {
  display: none;
}
h1::after {
  animation: txt 5s infinite;
  content: "";
}

@keyframes rt {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes cw {
  0% {
    width: 0;
    height: 0;
  }
  75% {
    width: 40px;
    height: 40px;
  }
  100% {
    width: 0;
    height: 0;
  }
}
@keyframes txt {
  0% {
    content: "LOADING.";
  }
  50% {
    content: "LOADING..";
  }
  100% {
    content: "LOADING...";
  }
}


/*
=====
UIA-TIMELINE
=====
*/

.uia-timeline__container {
  display: var(--uia-timeline-display, grid);
}

.uia-timeline__groups {
  display: var(--uia-timeline-groups-display, grid);
  gap: var(--uia-timeline-groups-gap, 1rem);
}

/*
SKIN 1
*/

[data-uia-timeline-skin="1"] .uia-timeline__step {
	line-height: 1;
	font-size: var(--uia-timeline-step-font-size, 2rem);
	font-weight: var(--uia-timeline-step-font-weight, 700);
	color: var(--uia-timeline-step-color);
}

[data-uia-timeline-skin="1"] .uia-timeline__point-intro {
	display: grid;
	grid-template-columns: min-content 1fr;
	align-items: center;
	gap: var(--uia-timeline-point-intro-gap, .5rem);
}

[data-uia-timeline-skin="1"] .uia-timeline__point-date {
	grid-row: 1;
	grid-column: 2;
}

[data-uia-timeline-skin="1"] .uia-timeline__point-heading {
	grid-column: span 2;
}

[data-uia-timeline-skin="1"] .uia-timeline__point-description {
	margin-block-start: var(--uia-timeline-group-gap, 1rem);
	inline-size: min(100%, var(--uia-timeline-content-max-limit));
}

/*
SKIN 2
*/

[data-uia-timeline-skin="2"] {
	--_uia-timeline-line-color_default: #222;
	--_uia-timeline-minimal-gap: var(--uia-timeline-minimal-gap, .5rem);
	--_uia-timeline-space: calc(var(--_uia-timeline-arrow-size) + var(--_uia-timeline-dot-size) + var(--_uia-timeline-dot-size) / 2 + var(--_uia-timeline-minimal-gap));
	--_uia-timeline-dot-size: var(--uia-timeline-dot-size, 1rem);
	--_uia-timeline-arrow-size: var(--uia-timeline-arrow-size, .25rem);
	--_uia-timeline-arrow-position: var(--uia-timeline-arrow-position, 1rem);
}

[data-uia-timeline-skin="2"] .uia-timeline__container {
	position: relative;
	padding-inline-start: calc(var(--_uia-timeline-space));
}

[data-uia-timeline-skin="2"] .uia-timeline__line {
	inline-size: var(--uia-timeline-line-thickness, 3px);
	block-size: 100%;
	background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

	position: absolute;
	inset-block-start: 0;
	inset-inline-start: calc(var(--_uia-timeline-dot-size) / 2);
	transform: translate(-50%);
}

[data-uia-timeline-skin="2"] .uia-timeline__group {
	position: relative;
}

[data-uia-timeline-skin="2"] .uia-timeline__dot {
	box-sizing: border-box;
	inline-size: var(--_uia-timeline-dot-size);
	block-size: var(--_uia-timeline-dot-size);

	border-radius: 50%;
	border: 
		var(--uia-timeline-dot-border-thickness, 1px) 
		solid 
		var(--uia-timeline-dot-border-color, var(--_uia-timeline-line-color_default));
	background-color: var(--uia-timeline-dot-color, var(--_uia-timeline-line-color_default));

	position: absolute;
	/* - 4px is used for set the default gap from the top border */
	inset-block-start: calc(var(--uia-timeline-dot-position, var(--_uia-timeline-arrow-position)) + 4px);
	inset-inline-start: calc(-1 * var(--_uia-timeline-space));
}	

[data-uia-timeline-skin="2"] .uia-timeline__point {
	position: relative;
	background-color: var(--uia-timeline-point-background-color, #fff);
}

[data-uia-timeline-skin="2"] .uia-timeline__point::before {
	content: "";
	inline-size: 0;
	block-size: 0;

	border: var(--_uia-timeline-arrow-size) solid var(--uia-timeline-arrow-color, var(--_uia-timeline-line-color_default));
	border-block-start-color: transparent;
	border-inline-end-color: transparent;

	position: absolute;
	/* - 6px is used for set the default gap from the top border */
	inset-block-start: calc(var(--_uia-timeline-arrow-position) + 6px);
	inset-inline-start: calc(-1 * var(--_uia-timeline-arrow-size) + 1px);
	transform: rotate(45deg);
}

[data-uia-timeline-adapter-skin-2="ui-card-skin-#1"] {
  --uia-card-padding: var(--uia-timeline-point-padding, 1.5rem 1.5rem 1.25rem);
  --uia-card-border-thickness: var(--uia-timeline-point-border-thickness, 3px);
  --uia-card-border-color: var(--uia-timeline-point-border-color, var(--_uia-timeline-line-color_default));		
  --uia-card-background-color: var(--uia-timeline-point-background-color);  
}

/*
SKIN 3
*/

[data-uia-timeline-skin="3"] {
  --_uia-timeline-line-color_default: #222;
  --_uia-timeline-space: var(--uia-timeline-space, 1rem);
  --_uia-timeline-line-thickness: var(--uia-timeline-line-thickness, 2px);
  --_uia-timeline-point-line-position: var(--uia-timeline-point-line-position, 1rem);
}

[data-uia-timeline-skin="3"] .uia-timeline__container {
  position: relative;
  gap: var(--uia-timeline-annual-sections-gap, 2.5rem);
}

[data-uia-timeline-skin="3"] .uia-timeline__line {
  inline-size: var(--_uia-timeline-line-thickness);
  block-size: 100%;
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

[data-uia-timeline-skin="3"] .uia-timeline__annual-sections {
  display: grid;
  gap: 2rem;
}

[data-uia-timeline-skin="3"] .uia-timeline__groups {
  padding-inline-start: var(--_uia-timeline-space);
}

[data-uia-timeline-skin="3"] .uia-timeline__group {
  position: relative;
  isolation: isolate;
}

[data-uia-timeline-skin="3"] .uia-timeline__point {
  background-color: var(--uia-timeline-point-background-color, #fff);
}

[data-uia-timeline-skin="3"] .uia-timeline__point::before {
  content: "";
  inline-size: 100%;
  block-size: var(--_uia-timeline-line-thickness);
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: var(--_uia-timeline-point-line-position);
  inset-inline-start: calc(-1 * var(--_uia-timeline-space));
  z-index: -1;
}

[data-uia-timeline-skin="3"] .uia-timeline__year {
  inline-size: fit-content;
  padding: var(--uia-timeline-year-padding, .25rem .75rem);
  background-color: var(--uia-timeline-year-background-color, var(--_uia-timeline-line-color_default));
  color: var(--uia-timeline-year-color, #f0f0f0);
}

[data-uia-timeline-adapter-skin-3="uia-card-skin-#1"] {
  --uia-card-padding: var(--uia-timeline-point-padding, 1.5rem 1.5rem 1.25rem);
  --uia-card-border-thickness:  var(--uia-timeline-point-border-thickness, 3px);
  --uia-card-border-color: var(--uia-timeline-point-border-color, var(--_uia-timeline-line-color_default));		
  --uia-card-background-color: var(--uia-timeline-point-background-color);  
}

/*
SKIN 4
*/

[data-uia-timeline-skin="4"] {
  --_uia-timeline-line-color_default: #222;
  --_uia-timeline-space: var(--uia-timeline-space, .5rem);
  --_uia-timeline-line-thickness: var(--uia-timeline-line-thickness, 2px);
	--_uia-timeline-annual-sections-safe-gap: var(--uia-timeline-annual-sections-safe-gap, 1.5rem); 
  --_uia-timeline-point-line-position: var(--uia-timeline-point-line-position, 1rem);
	--_uia-timeline-year-size: var(--uia-timeline-year-size, 3.5rem);
}

[data-uia-timeline-skin="4"] .uia-timeline__container {
  position: relative;
  gap: var(--uia-timeline-annual-sections-gap, 2.5rem);
}

[data-uia-timeline-skin="4"] .uia-timeline__line {
  inline-size: var(--_uia-timeline-line-thickness);
  block-size: 100%;
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: 0;
  inset-inline-start: calc(var(--_uia-timeline-year-size) / 2);
	transform: translate(-50%);
	z-index: -1;
}

[data-uia-timeline-skin="4"] .uia-timeline__annual-sections {
  display: flex;
	align-items: flex-start;
	isolation: isolate;
}

[data-uia-timeline-skin="4"] .uia-timeline__groups {
  padding-inline-start: var(--_uia-timeline-space);
	padding-block-start: calc(var(--_uia-timeline-year-size) + var(--_uia-timeline-annual-sections-safe-gap));
}

[data-uia-timeline-skin="4"] .uia-timeline__group {
  position: relative;
  isolation: isolate;
}

[data-uia-timeline-skin="4"] .uia-timeline__point {
  background-color: var(--uia-timeline-point-background-color, #fff);
}

[data-uia-timeline-skin="4"] .uia-timeline__point::before {
  content: "";
  inline-size: 100%;
  block-size: var(--_uia-timeline-line-thickness);
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: var(--_uia-timeline-point-line-position);
  inset-inline-start: calc(-1 * (var(--_uia-timeline-space) + var(--_uia-timeline-year-size) / 2));
  z-index: -1;
}

[data-uia-timeline-skin="4"] .uia-timeline__year {
  box-sizing: border-box;
	flex: none;
	inline-size: var(--_uia-timeline-year-size);
	block-size: var(--_uia-timeline-year-size);
	
	border: 
		var(--uia-timeline-year-line-thickness, var(--_uia-timeline-line-thickness)) 
		var(--uia-timeline-year-line-style, solid) 
		var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));
	border-radius: 50%;
	
	display: grid;
	place-items: center;
  background-color: var(--uia-timeline-year-background-color, #f0f0f0);
	
	font-size: var(--uia-timeline-year-font-size, .75rem);
  color: var(--uia-timeline-year-color, #222);
}

[data-uia-timeline-adapter-skin-4="uia-card-skin-#1"] {
  --uia-card-padding: var(--uia-timeline-point-padding, 1.5rem 1.5rem 1.25rem);
  --uia-card-border-thickness:  var(--uia-timeline-point-border-thickness, 3px);
	--uia-card-border-color: var(--uia-timeline-point-border-color, var(--_uia-timeline-line-color_default));	
  --uia-card-background-color: var(--uia-timeline-point-background-color);  
}

/*
SKIN 5
*/

[data-uia-timeline-skin="5"] {
	--_uia-timeline-line-color_default: #222;
	--_uia-timeline-line-thickness: var(--uia-timeline-line-thickness, 3px);
	--_uia-timeline-line-calculation: calc(var(--_uia-timeline-dot-size) / 2  - calc(var(--_uia-timeline-line-thickness) / 2));
	--_uia-timeline-line-position: var(--uia-timeline-line-position, 0px);

	--_uia-timeline-minimal-gap: var(--uia-timeline-minimal-gap, .5rem);
	--_uia-timeline-space: calc(var(--_uia-timeline-dot-size) + var(--_uia-timeline-dot-size) / 2 + var(--_uia-timeline-minimal-gap));

	--_uia-timeline-dot-size: var(--uia-timeline-dot-size, 1rem);
}

[data-uia-timeline-skin="5"] .uia-timeline__container {
	position: relative;
	padding-inline-start: var(--_uia-timeline-space);
}

[data-uia-timeline-skin="5"] .uia-timeline__line {
	border-inline-start: var(--_uia-timeline-line-thickness) solid var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));
	block-size: calc(100% - var(--_uia-timeline-line-position));

	position: absolute;
	inset-block-start: var(--_uia-timeline-line-position);
	inset-inline-start: var(--uia-timeline-line-main-position, 	var(--_uia-timeline-line-calculation));
}

[data-uia-timeline-skin="5"] .uia-timeline__group {
	position: relative;
	isolation: isolate;
}

[data-uia-timeline-skin="5"] .uia-timeline__dot {
	box-sizing: border-box;
	font-size: var(--_uia-timeline-dot-size);

	border-radius: 50%;
	box-shadow: 0 0 0
		var(--uia-timeline-dot-border-thickness, 1px)  
		var(--uia-timeline-dot-border-color, var(--_uia-timeline-line-color_default));
	border: .5em solid var(--uia-timeline-dot-color, var(--_uia-timeline-line-color_default));

	position: absolute;
	inset-block-start: var(--uia-timeline-dot-position, -1px);
	inset-inline-start: var(--uia-timeline-dot-main-position, calc(-1 * var(--_uia-timeline-space)));
}	

[data-uia-timeline-skin="5"] .uia-timeline__group:last-child::after {
	content: "";
	inline-size: calc(var(--_uia-timeline-line-thickness) + var(--uia-timeline-line-mask-thickness, 4px));
	block-size: calc(100% - var(--_uia-timeline-line-position) - var(--_uia-timeline-dot-size) / 2);
	background-color: var(--uia-timeline-line-mask-color);
	position: absolute;
	inset-block-end: 0;
	inset-inline-start: var(--_uia-timeline-line-mask-main-position, calc((var(--_uia-timeline-space) - var(--_uia-timeline-dot-size) / 2) * -1));
	transform: translate(-50%);	
	z-index: -1;
}

[data-uia-timeline-skin="5"] {
	container-type: inline-size;
	container-name: timeline-skin-5;
}

@container timeline-skin-5 (min-width: 600px) {

	[data-uia-timeline-skin="5"] .uia-timeline__container {
		--uia-timeline-line-main-position: calc(50% - (var(--uia-timeline-line-thickness) / 2));	
		--uia-timeline-dot-main-position: 50%;
		--_uia-timeline-space: 0;
	}

	[data-uia-timeline-skin="5"] .uia-timeline__point {
		max-inline-size: 45%;
	}

	[data-uia-timeline-skin="5"] .uia-timeline__group:nth-child(even) .uia-timeline__point {
		margin-inline-start: auto;
	}

	[data-uia-timeline-skin="5"] .uia-timeline__group:nth-child(odd) .uia-timeline__point {
		text-align: end;		
	}

	[data-uia-timeline-skin="5"] .uia-timeline__dot {
		transform: translateX(-50%);
	}

	[data-uia-timeline-skin="5"] .uia-timeline__group:last-child::after {
		--_uia-timeline-line-mask-main-position: var(--uia-timeline-line-main-position);
	}
}

[data-uia-timeline-adapter-skin-5="uia-card"] {
	--uia-card-content-gap: var(--uia-timeline-content-gap, .5rem);  
}

/*
=====
RESET
=====
*/

:where(.ra-link) {
  display: var(--ra-link-display, inline-flex);
}

:where(.ra-link[href]) {
  color: var(--ra-link-color, inherit);
  text-decoration: var(--ra-link-text-decoration, none);
}

:where(.ra-heading) {
  margin-block-start: var(--ra-heading-margin-block-start, 0);
  margin-block-end: var(--ra-heading-margin-block-end, 0);
}

/*
=====
HELPERS
=====
*/

.ha-screen-reader {
  width: var(--ha-screen-reader-width, 1px);
  height: var(--ha-screen-reader-height, 1px);
  padding: var(--ha-screen-reader-padding, 0);
  border: var(--ha-screen-reader-border, none);

  position: var(--ha-screen-reader-position, absolute);
  clip-path: var(--ha-screen-reader-clip-path, rect(1px, 1px, 1px, 1px));
  overflow: var(--ha-screen-reader-overflow, hidden);
}

/*
=====
UIA-CARD
=====
*/

.uia-card__container {
	display: var(--uia-card-display, grid);
	gap: var(--uia-card-content-gap);
}

.uia-card__time-divider::before {
  content: "—";
  margin-inline: var(--uia-card-time-divider-margin-inline, .15rem);
}

[data-uia-card-skin="1"] .uia-card__container {
  background-color: var(--uia-card-background-color);
  border-radius: var(--uia-card-border-radius, 2px);
}

[data-uia-card-skin="1"] .uia-card__intro {
  display: grid;
  gap: var(--uia-card-intro-gap, 1rem);
}

[data-uia-card-skin="1"] .uia-card__time {
  grid-row: 1 / 1;
    inline-size: -moz-fit-content;
    inline-size: fit-content;
    padding: var(--uia-card-time-padding, .25rem 1.25rem .25rem);
    background-color: #4557bb;
    color: white;
    font-weight: var(--uia-card-time-font-weight, 700);
    font-size: var(--uia-card-time-font-size, .75rem);
    text-transform: var(--uia-card-time-text-transform, uppercase);
    width: 100%;
}

[data-uia-card-skin="1"][data-uia-card-mod="1"] .uia-card__container {
	border-inline-start:	var(--uia-card-border-thickness, 2px) var(--uia-card-border-style, solid) var(--uia-card-border-color, currentColor);
	box-shadow: var(--uia-card-box-shadow, 0 1px 3px 0 rgba(0, 0, 0, .12), 0 1px 2px 0 rgba(0, 0, 0, .24));
}

/*
=====
UIA-CONTROL
=====
*/

.uia-control__group {
	cursor: var(--uia-control-cursor, var(--_uia-control-cursor, pointer));	
	line-height: var(--uia-control-line-height, 1);
}

.uia-control {
	--_uia-control-padding_default: 0;

	--_uia-control-border-width: var(--uia-control-border-width);
	--_uia-control-border-style: var(--uia-control-border-style, solid);
	--_uia-control-border-color: var(--uia-control-border-color, currentColor);

	--_uia-control-padding: var(--uia-control-padding,
														var(
															--uia-control-padding-block-start,
															var(--_uia-control-padding_default)
														)
														var(
															--uia-control-padding-inline-end,
															var(--_uia-control-padding_default)
														)
														var(
															--uia-control-padding-block-end, 
															var(--_uia-control-padding_default)
														)
														var(
															--uia-control-padding-inline-start,
															var(--_uia-control-padding_default)
														)
													);
	--_uia-control-border: var(--_uia-control-border-width) var(--_uia-control-border-style) var(--_uia-control-border-color);
	--_uia-control-border-radius: var(--uia-control-border-radius, .25rem);
}

.uia-control__group {
	display: var(--uia-control-display, inline-flex);
	gap: var(--uia-control-gap, .5rem);
	
	padding: var(--_uia-control-padding);

	border: var(--_uia-control-border);
	border-radius: var(--_uia-control-border-radius);
	background-image: linear-gradient(
		var(--uia-control-background-degree, 135deg),
		var(
			--uia-control-background-start-color,
			var(--uia-control-background-color)
		) var(--uia-control-background-start-position, 0),
		var(
			--uia-control-background-end-color,
			var(--uia-control-background-color)
		) var(--uia-control-background-end-position, 100%)
	);
}

/*
=====
TYPOGRAPHY
=====
*/


p:not([class]) {
  margin-block: var(--ds-typography-paragraph-margin-block-start, var(--_ds-typography-main-margin)) 0;
}

/*
=====
DEMO
=====
*/

:root {
  --uia-timeline-line-color: #4557bb;
  --uia-timeline-dot-color: #4557bb;
  --uia-timeline-arrow-color: #4557bb;
  --uia-timeline-line-thickness: 3px;
  --uia-timeline-point-border-color: #4557bb;
  --uia-timeline-group-padding: 1.5rem 1.5rem 1.25rem;
  
  --ds-typography-main-line-height: 1.5;
}

.timeline-5-2 {
	--uia-timeline-line-mask-color: #f0f0f0
}

[data-uia-timeline-skin="3"] {
	--uia-timeline-year-background-color: #4557bb;
}

body {
  background-color: #f0f0f0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.page {
	box-sizing: border-box;
  inline-size: min(100%, 90ch);
  margin-inline: auto;
}

.linktr {
  --uia-control-background-color: rgb(209, 246, 255);
  --uia-control-border-radius: 2rem;
  --uia-control-padding: .75rem 1.5rem;
  --uia-control-border-width: 1px;
  --uia-control-border-color: rgb(209, 246, 255);
  
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  text-align: center;
}

.linktr__goal { 
  box-shadow: rgb(8 49 112 / 24%) 0 2px 8px 0;  
}

.color-box {
  width: 100px; /* Set the width */
  height: 100px; /* Set the height to match width */
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 5px; /* Optional: Add rounded corners */
  text-align: center;
  font-size: 1rem;
  padding: 2px;
}
.color-box p {
  font-size: 17px;
}

.timeline-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.timeline-container.visible {
  max-height: 500px; /* Adjust to fit the content height */
}

.timeline-container.hidden {
  max-height: 0;
}


.badge {
  display: inline-flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 30px;
  position: relative;
  margin: 0 10px;
  text-transform: uppercase;
}
.badge i {
  margin: 0 10px;
}
.badge.in {
  background-color: #28a745; /* Green for success */
  clip-path: polygon(0 50%, 10% 0, 100% 0, 100% 100%, 10% 100%);
  cursor: pointer;

}
.badge.out {
  background-color: #dc3545; /* Red for danger */
  clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
  cursor: pointer;

}